export type TSupportedLanguages =
  | "en"
  | "fi"
  | "sv"
  | "cs"
  | "de"
  | "hu"
  | "ro";
export const SUPPORTED_LANGUAGES = [
  "en",
  "fi",
  "sv",
  "cs",
  "de",
  "hu",
  "ro",
] as const;

export const LANGUAGE_NAMES: { [key in TSupportedLanguages]: string } = {
  en: "English",
  fi: "Suomi",
  sv: "Svenska",
  cs: "Čeština",
  de: "Deutsch",
  hu: "Magyar nyelv",
  ro: "Limba română",
} as const;

export const FALLBACK_LANGUAGE = "en";
