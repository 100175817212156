import React from "react";
import { Link } from "wouter";

import { Box } from "@mui/material";
import logo from "../assets/image/logo.png";

export interface IRauliLogo {
  withoutLink?: boolean;
  maxWidth?: string;
  callback?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const RauliLogo = (props: IRauliLogo) => {
  const { withoutLink, maxWidth, callback } = props;
  const logoImg = (
    <img
      src={logo}
      className="RauliLogo"
      alt="logo"
      style={
        withoutLink
          ? { display: "block", width: "100%", height: "auto" }
          : { maxWidth: maxWidth ?? "100%", cursor: "pointer" }
      }
    />
  );

  if (withoutLink) {
    return <Box sx={{ maxWidth: maxWidth || "100%" }}>{logoImg}</Box>;
  }

  return (
    <Link to="/" onClick={(event) => callback && callback(event)}>
      {logoImg}
    </Link>
  );
};

export default RauliLogo;
