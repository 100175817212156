import { DateTime } from "luxon";
import { makeObservable, observable, runInAction } from "mobx";
import { listenForNotificationChanges } from "../firebase/notification";
import { INotification } from "../firebase/notification.interfaces";
import { INotificationStorage } from "./Notification.interfaces";

class NotificationStorage implements INotificationStorage {
  notifications: INotification[] = [];
  _unsubscribe: (() => void) | null = null;

  constructor() {
    makeObservable(this, {
      notifications: observable,
      _unsubscribe: observable,
    });

    window.onbeforeunload = () => {
      this.unsubscribe();
    };

    window.addEventListener("beforeunload", () => {
      this.unsubscribe();
    });
  }

  subscribe = () => {
    const unsubscribe = listenForNotificationChanges((snapshot) => {
      runInAction(() => {
        this.notifications = snapshot.docs.map(
          (x) =>
            ({
              ...x.data(),
              id: x.id,
              date: DateTime.fromISO(x.data().date),
            } as INotification)
        );
      });
    });
    runInAction(() => {
      this._unsubscribe = unsubscribe;
    });
  };

  unsubscribe = () => {
    if (this._unsubscribe !== null) {
      this._unsubscribe();
    }
    runInAction(() => {
      this._unsubscribe = null;
    });
    this.clear();
  };

  clear = () => {
    runInAction(() => {
      this.notifications = [];
    });
  };
}

const notificationStorage = new NotificationStorage();
export default notificationStorage;
