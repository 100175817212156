import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { observer } from "mobx-react-lite";
import { SnackbarProvider } from "notistack";
import React, { lazy, Suspense, useEffect } from "react";
import { Redirect, Route, RouteProps, Switch } from "wouter";
import LoadingSpinner from "./components/LoadingSpinner";
import RauliError from "./components/RauliError";
import UpdateBanner from "./components/UpdateBanner";
import StaticRedirect from "./pages/StaticRedirect";
import notificationStorage from "./storage/Notification";
import userStorage from "./storage/User";
import theme from "./theme/dark";
import i18n from "./translations/i18n";
import { TSupportedLanguages } from "./translations/i18n.interfaces";
import { setLuxonLocale } from "./utils/changeLanguage";

const Configure = lazy(() => import("./pages/Configure"));
// const LeadManagement = lazy(() => import("./pages/LeadManagement"));
const Admin = lazy(() => import("./pages/Admin"));
const Frontpage = lazy(() => import("./pages/Frontpage"));
const Login = lazy(() => import("./pages/Login"));
const Registration = lazy(() => import("./pages/Registration"));
const ProjectBasket = lazy(() => import("./pages/ProjectBasket"));
const Wholesale = lazy(() => import("./pages/Wholesale"));
const MaterialBank = lazy(() => import("./pages/MaterialBank"));
const Terms = lazy(() => import("./pages/Terms"));
const Privacy = lazy(() => import("./pages/Privacy"));
const APIDocs = lazy(() => import("./pages/APIDocs"));
const APITokens = lazy(() => import("./pages/APITokens"));
const Flatroof = lazy(() => import("./pages/Flatroof"));
const ComponentProperties = lazy(() => import("./pages/ComponentProperties"));
const OfferRequest = lazy(() => import("./pages/OfferRequest"));
const OfferRequestList = lazy(() => import("./pages/OfferRequestList"));
const Page404 = lazy(() => import("./pages/404"));
// const Lead = lazy(() => import("./pages/Lead"));
const AdminElectricalComponent = lazy(
  () => import("./pages/AdminElectricalComponent")
);

interface IPrivateRoute extends RouteProps {
  allowUnverified?: boolean;
}

const PrivateRoute = observer((props: IPrivateRoute) => {
  const loggedIn = userStorage.uid !== undefined;
  const accountVerified = userStorage.email_verified;

  if (!loggedIn) {
    return (
      <Route path={props.path}>
        <Redirect to="/login" />
      </Route>
    );
  }
  if (props.allowUnverified !== true && !accountVerified) {
    return <Route {...props} component={Frontpage} />;
  }
  return <Route {...props} />;
});

const App = observer(() => {
  const loggedIn = userStorage.uid !== undefined;
  setLuxonLocale(i18n.language as TSupportedLanguages);

  useEffect(() => {
    if (!loggedIn) {
      notificationStorage.unsubscribe();
      return;
    }
    // Returns unsubscribe function
    notificationStorage.subscribe();
    return () => {
      notificationStorage.unsubscribe();
    };
  }, [loggedIn]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        variant="error"
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={6000}
      >
        <CssBaseline />
        <UpdateBanner />
        <RauliError>
          <Suspense fallback={<LoadingSpinner />}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/register" component={Registration} />
              <Route path="/material/:media" component={StaticRedirect} />
              <Route path="/material" component={MaterialBank} />
              <PrivateRoute path="/configure" component={Configure} />
              {/* <Route path="/lead/:uuid" component={Lead} /> */}
              <PrivateRoute
                path="/admin/:user/p/:project"
                component={Configure}
              />
              <PrivateRoute
                path="/admin/:user/f/:project"
                component={Flatroof}
              />
              <PrivateRoute
                path="/admin/component"
                component={ComponentProperties}
              />
              <PrivateRoute
                path="/admin/electrical"
                component={AdminElectricalComponent}
              />
              <PrivateRoute path="/admin" component={Admin} />
              <PrivateRoute path="/wholesale" component={Wholesale} />
              <PrivateRoute
                path="/offer_request"
                component={OfferRequestList}
              />
              <PrivateRoute
                path="/offer_request/:project"
                component={OfferRequest}
              />
              <PrivateRoute path="/basket" component={ProjectBasket} />
              <PrivateRoute path="/profile/token" component={APITokens} />
              {/* <PrivateRoute path="/profile/lead" component={LeadManagement} /> */}
              <PrivateRoute path="/profile" component={Registration} />
              <Route path="/terms" component={Terms} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/doc/api" component={APIDocs} />
              <PrivateRoute path="/flatroof" component={Flatroof} />
              <Route path="/" component={loggedIn ? Frontpage : Login} />
              <Route path="/:rest" component={Page404} />
            </Switch>
          </Suspense>
        </RauliError>
      </SnackbarProvider>
    </ThemeProvider>
  );
});

export default App;
